import appStore from "@/store/App.store";
import helpers from "@/store/helpers";
import http from "axios";
import {cdiVars} from "@/cdiVars";

export const myStore = {
	state: {
		data: {},
		currentView: 'search',
	},

	post: async function(url, qsPairs, resultField){
		appStore.loadingMessage();
		let qsString = helpers.getQSString(qsPairs);
		return await http.post(url, qsString, { headers: helpers.authHeader() })
			.then(res => this.handleResponse(resultField, res.data))
			.catch();
	},

	handleResponse: function(saveToState, data) {
        if (data.opt == 'denied') {
            appStore.autoSignOut();
        }else if (data.opt == 'error') {
			appStore.errorBox(data.msg);
		}else{
			appStore.successBox(data.msg);
			if (!saveToState){
				return data;
			}else {
				this.state.data = data
			}
		}
	},

	api: function(endPoint, options, saveToState = false) {
		return new Promise(resolve =>
			resolve(this.post(`${cdiVars.API_URL}/${endPoint}/`, options, saveToState))
		);
	},

	staticApi: function(endPoint, options, saveToState = false) {
		return new Promise(resolve =>
			resolve(this.post(`${cdiVars.API_URL}/${endPoint}`, options, saveToState))
		);

	}
}