<template>

    <div v-if="allowPage">
        <div v-if="loggedIn">
            <div>
                <div v-if="appState.user.userTypeID == 5">
                    <h1>Thank you for registering with CDI!</h1>
                    <p>A CDI representative will review your registration shortly, and if approved, will grant you access to quoting features.</p>
                </div>
                <component v-else :id="id" v-bind:is="currentComponent"></component>
            </div>
        </div>
        <div v-else-if="!loggedIn">
            <SignInPage></SignInPage>
        </div>
    </div>
    <div v-else-if="!allowPage">
        <div>
            <component :id="id" v-bind:is="currentComponent"></component>
        </div>
    </div>

</template>

<script>
import { myStore } from "@/store/My.store"
import SignInPage from "@/views/SignInPage";

export default {
    name: 'My',
    reg: '',
    props: ['id'],
    components: {
        SignInPage,
        myOrder: () => import('@/components/my/myOrders/Container'),
        drawings: () => import('@/components/my/Drawings'),
        myEdit: () => import('@/components/my/MyEdit'),
        myCdi: () => import('@/components/my/MyCdi'),
        myAlerts: () => import('@/components/my/MyAlerts'),
        passwordReset: () => import('@/components/my/PasswordReset'),
        register: () => import('@/components/my/Register'),
    },
    data() {
        return {
            state: myStore.state,
            appState: this.$appStore.state,
        }
    },
    computed: {
        currentComponent: function() {
            // this computed returns the route name instead of the route path
            // so a nested path can be used for /my/register
            return this.$route.name;
        },
        loggedIn: function() {
            return this.appState.loggedIn
        },
        allowPage: function (){
            if (this.$route.name == 'register' || this.$route.name == 'passwordReset') {
                return false;
            }else {
                return true;
            }
        }
    }
}
</script>

<style scoped>

</style>